import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';

const ATMDrivingAndMonitoring = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.atm_driving_and_monitoring}`,
    message: (
      <div className="ui bulleted list">
        <div className="item">{messages.message_atm_driving_and_monitoring_item1}</div>
        <div className="item">{messages.message_atm_driving_and_monitoring_item2}</div>
        <div className="item">{messages.message_atm_driving_and_monitoring_item3}</div>
        <div className="item">{messages.message_atm_driving_and_monitoring_item4}</div>
        <div className="item">{messages.message_atm_driving_and_monitoring_item5}</div>
        <div className="item">{messages.message_atm_driving_and_monitoring_item6}</div>
      </div>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.atm_driving_and_monitoring}`} textBlocks={textBlocks}/>);
};

export default ATMDrivingAndMonitoring;